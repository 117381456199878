class User{

    constructor(name, mobile, email , password,username,role) {
       this.name=name;
       this.mobile=mobile;
       this.email=email;
       this.password=password;
       this.username=username;
       this.role=role        
    }

    
}

export default User;